import React, { Component } from 'react';
import { Container } from "reactstrap";
import { appStore, googlePlay, heroImg, qrcodePH, speedArrows } from "../../../assets/images";
import './style.css';

class Hero extends Component {

  randomNumber = () => {
    const date = new Date();
    const generator = new Math.seedrandom(date.toDateString());
    const randomNumber = generator() * 20 + 12;
    return Math.floor(randomNumber);
  }

  render() {
    return (
      <section className="section hero">
        <Container>
          <div className="hero-inner">
            <div className="hero-left">
              <h1 className="hero-title">Scan. Analyze. Profit.</h1>
              <p className="hero-subtitle">Discover the potential of every product on Amazon with a single scan.</p>
              <div className="d-flex gap-3 hero-links">
                <img 
                  src={qrcodePH}
                  alt="QR Code Profit Hunt™"
                  width={130}
                  className="mb-3"
                />
                <div className="hero-cta">
                  <div className="hero-downloads">
                    <a href="https://play.google.com/store/apps/details?id=com.profithunt">
                      <img src={googlePlay} alt="Download Profit Hunt on Google Play" />
                    </a>
                    <a href="https://apps.apple.com/us/app/profithunt/id1484365065">
                      <img src={appStore} alt="Download Profit Hunt on the AppStore" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="hero-cta-fomo">
                <img src={speedArrows} alt="" />
                <p>
                  <strong>Hurry:</strong> {this.randomNumber()} sellers signed up <strong className="text-warning">today</strong>.
                  Act now and start <br/>making $$$ on Amazon and eBay!
                </p>
              </div>
            </div>
            <div className="hero-right">
              <img src={heroImg} alt="" className="img-fluid" />
            </div>
          </div>
        </Container>
      </section>
    )
  }
}

export default Hero;
