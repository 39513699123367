import React, { Component } from 'react';
import './style.css';
import { Container } from "reactstrap";

const features = [
  'pScore™ profit calculation',
  'Downloadable database',
  'SBYB integration',
  'Smart triggers',
  'Speed mode',
  'Profit-based approach',
  'Add-to-batch functionality',
  'Custom MSKUs'
]

class Pricing extends Component {
  render() {
    return (
      <section className="section pricing" id="pricing">
        <Container>
          <h2 className="section-title">Choose your plan</h2>
          <div className="pricing-wrapper">
            <div className="pricing-box">
              <div className="pricing-box-content">
                <div className="pricing-box-price">
                  <span className="pricing-box-price-number">$0</span>
                  <span className="pricing-box-price-period">/ month</span>
                </div>
                <h2 className="pricing-box-title">Free</h2>
                <p className="pricing-box-description">For AccelerList customers</p>
                <hr className="pricing-box-separator" />
                <ul className="pricing-box-list">
                  {features.map((item, idx) => (
                  <li key={`free-${idx}`}>{item}</li>
                  ))}
                </ul>
              </div>
              <a href="https://app.profithunt.io/login" className="btn btn-success d-block">Get Started</a>
            </div>
            <div className="pricing-box">
              <div className="pricing-box-content">
                <div className="pricing-box-price">
                  <span className="pricing-box-price-number">$19.99</span>
                  <span className="pricing-box-price-period">/ month</span>
                </div>
                <h2 className="pricing-box-title">PRO</h2>
                <p className="pricing-box-description">For LIVE & Database scanning</p>
                <hr className="pricing-box-separator" />
                <ul className="pricing-box-list">
                  {features.map((item, idx) => (
                  <li key={`pro-${idx}`}>{item}</li>
                  ))}
                </ul>
              </div>
              <a href="https://app.profithunt.io/register" className="btn btn-success d-block">Get Started</a>
            </div>
          </div>
        </Container>
      </section>
    )
  }
}

export default Pricing;
