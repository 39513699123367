import React, { Component } from 'react';
import './App.css';
import Home from "./views/Home";

class App extends Component {
  render() {
    return (
      <div className="wrapper">
        <Home />
      </div>
    )
  }
}
export default App;