import React, { Component } from 'react';
import { Col, Container, Row } from "reactstrap";
import Slider from 'react-slick';
import { iconStar } from "../../../assets/icons";
import './style.css';
import '@mux/videojs-kit';
import '@mux/videojs-kit/dist/index.css';
import VideoPlayer from "../../../shared/components/VideoPlayer";

const DEFAULT_GROUP_COUNT = 3

class Testimonial extends Component {
  state = {
    groupCount: DEFAULT_GROUP_COUNT,
    testimonials: null,
  }

  fetchData = async () => {
    try {
      const response = await fetch('https://back-d7r6.onrender.com/application/testimonials');
      const result = await response.json();
      this.setState({testimonials: result?.testimonials || []})
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  componentDidMount = () => {
    this.updateGroupCount()
    window.addEventListener('resize', this.updateGroupCount)
    this.fetchData();
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateGroupCount)
  }

  updateGroupCount = () => {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    let groupCount = DEFAULT_GROUP_COUNT

    if(screenWidth < 992) {
      groupCount = 2
    }
    if (screenWidth < 670) {
      groupCount = 1
    }
    this.setState({ groupCount })
  }

  render() {
    const { testimonials } = this.state;
    const groupTestimonials = []
    if(testimonials) {
      // group testimonial by groupCount items for best visual slider purposes
      let group = []
      for (let i = 0; i < testimonials.length; i++) {
        if(i > 0 && i % this.state.groupCount === 0) {
          groupTestimonials.push(group)
          group = []
        }
        group.push(testimonials[i])
      }
      // push the leftovers
      groupTestimonials.push(group)
    }

    return (
      <section className="section testimonial">
        <Container>
          <h2 className="section-title">What's sellers say about our support</h2>
          <Slider
            accessibility={false}
            touchMove={false}
            swipe={false}
            arrows={true}
            dots={false}
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={true}
            adaptiveHeight={true}
          >
            {groupTestimonials?.map((group, idxGroup) => (
              <div className="testimonial-group" key={`group-${idxGroup}`} tabIndex={-1}>
                {group?.map((item, idx) => (
                  <div className="testimonial" key={`testimonial-${idx}`}>
                    <div className="testimonial-top">
                      <div className="testimonial-rating">
                        <StarRating
                          id={idx}
                          rating={item?.testimonial?.rating}
                        />
                      </div>
                      <div className="testimonial-content">
                        {item?.testimonial?.text}
                      </div>
                    </div>
                    <strong className="testimonial-name">{item?.testimonial?.sent_by?.name}</strong>
                  </div>
                ))}
              </div>
            ))}

          </Slider>
          <Row className="mt-5">
            <Col md={4}>
              <VideoPlayer title="Anna K" id="NOsMrV014B7UnIGF601Xl5bXIgDoGUS2558Cpk8MCdjjM" videoClassname="vjs-16-9"/>
            </Col>
            <Col md={4}>
              <VideoPlayer title="Jordan S" id="1FBpYXW3oa00cgCXCK00zXdz6cXv4f8zx6rbhfT6IT5FM" videoClassname="vjs-16-9"/>
            </Col>
            <Col md={4}>
              <VideoPlayer title="Robert K" id="g8y1jXuiVKe2nAeMnG8EzjzfM2WzTRK00v400s5ZOS9m00" videoClassname="vjs-16-9"/>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <VideoPlayer title="Jennifer E" id="9gxB6VEMgSd7xshXkdo7QYu7Hu021fY6WHm01H7qQGQQM" videoClassname="vjs-16-9"/>
            </Col>
            <Col md={4}>
              <VideoPlayer title="Joshua N" id="pHo5zwLN4sgVqEeGAJfeAyd02zbYabpNg9zRz9GeNeYg" videoClassname="vjs-16-9" />
            </Col>
            <Col md={4}>
              <VideoPlayer title="Patrick S" id="GZZtGfcHO800HFGohDnYlrCuzQDYOCzW007C01R9c6FSoU" videoClassname="vjs-16-9" />
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

const StarRating = ({id, rating}) => {
  if(rating > 0) {
    return (
      <div className="star-rating mb-1">
      {Array.from(Array(rating)).map((item, idx) => (
        <img src={iconStar}  key={`rating-${id}-${idx}`} alt="" />
      ))}
      </div>
    )
  }
  return null
}

export default Testimonial;
