import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { logo } from "../../../assets/images";
import './style.css';

class TopNav extends Component {
  state = {
    isOpen: false
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <Navbar expand="lg" container="md">
        <NavbarBrand href="/">
          <img src={logo} alt="Profit Hunt Logo" className="img-fluid" />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <NavLink href="#features">Features</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#pricing">Pricing</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://accelerlist.helpjuice.com/" target="_blank" rel="noopener noreferrer">Support</NavLink>
            </NavItem>
            <NavItem>
              <a href="https://app.profithunt.io/login" className="btn btn-success">Sign In</a>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

export default TopNav;
