import React, { Component } from 'react';
import TopNav from "../../shared/components/TopNav";
import Hero from "./Hero";
import Blurb from "./Blurb";
import Features from "./Features";
import Pricing from "./Pricing";
import Download from "./Download";
import PageFooter from "../../shared/components/PageFooter";
import Testimonial from "./Testimonial";
import FeaturesGrid from "./FeaturesGrid";

class Home extends Component {
  render() {
    return (
      <div className="page-home">
        <TopNav />
        <Hero />
        <Blurb />
        <Features />
        <FeaturesGrid />
        <Testimonial />
        <Pricing />
        <Download />
        <PageFooter />
      </div>
    )
  }
}

export default Home;