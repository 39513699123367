import React, { Component } from 'react';
import { Col, Container, Row } from "reactstrap";
import { accelerlistLogo } from "../../../assets/images";
import './style.css';

class PageFooter extends Component {
  render() {
    return (
      <footer className="page-footer">
        <Container>
          <Row>
            <Col xl={6} lg={5}>
              <div>
                Profit Hunt™ is a product of <br/>
                <span className="d-block mt-3">
                  <img src={accelerlistLogo} alt="AccelerList logo" />
                  <p className="small mt-1 mb-0">Amazon product listing, repricing and accounting. <br/>
                    All in a single application, built for the future.</p>
                </span>
              </div>
            </Col>
            <Col xl={6} lg={7}>
              <h6 className="page-footer-heading">Helpful Links</h6>
              <ul className="page-footer-links">
                <li><a href="https://www.accelerlist.com/blog" target="_blank" rel="noopener noreferrer">Blog</a></li>
                <li><a href="https://accelerlist.helpjuice.com/" target="_blank" rel="noopener noreferrer">Help &amp; Support</a></li>
                <li><a href="https://www.accelerlist.com/about_us" target="_blank" rel="noopener noreferrer">About Us</a></li>
                <li><a href="https://www.facebook.com/groups/accelerlist/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                <li><a href="https://www.youtube.com/channel/UCbWDBnhZ21OHla7ESB9SYwQ" target="_blank" rel="noopener noreferrer">YouTube</a></li>
              </ul>
              <p className="page-footer-copyright">Copyright {new Date().getFullYear()} AccelerList.com</p>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}

export default PageFooter;