import React, { Component } from 'react';
import { Container } from "reactstrap";
import { appStoreLight, googlePlayLight, qrcodePH } from "../../../assets/images";
import './style.css';

class Download extends Component {
  render() {
    return (
      <section className="section download">
        <Container>
          <h2 className="section-title">Download<br/> Profit Hunt™</h2>
          <p className="section-subtitle">Start your side hustle today! Join thousands of other Amazon sellers.</p>
          <div className="d-flex gap-3 justify-content-center download-links">
            <img 
              src={qrcodePH}
              alt="QR Code Profit Hunt™"
              width={130}
              className="mb-3"
            />
            <div className="download-buttons">
              <a href="https://play.google.com/store/apps/details?id=com.profithunt">
                <img src={googlePlayLight} alt="Download Profit Hunt on Google Play" />
              </a>
              <a href="https://apps.apple.com/us/app/profithunt/id1484365065">
                <img src={appStoreLight} alt="Download Profit Hunt on the AppStore" />
              </a>
            </div>
          </div>
        </Container>
      </section>
    )
  }
}

export default Download;