import React, { Component } from 'react';
import { Container } from "reactstrap";
import { feature1, feature2, feature3 } from "./images";
import './style.css';

class Features extends Component {
  render() {
    return (
      <div id="features">
        <section className="section feature">
          <Container>
            <div className="feature-inner">
              <div className="feature-image">
                <img src={feature1} alt="" className="img-fluid" />
              </div>
              <div className="feature-text">
                <h2 className="feature-intro">Profit Hunt™ Product Page</h2>
                <h3 className="feature-title">Decisions are made when data meets speed.</h3>
                <hr className="feature-separator" />
                <p>Leverage the power of Amazon APIs along with our proprietary pScore™ 
                  to figure out which channel will make you the most money.  Merchant Fulfilled? FBA? SBYB or trade in?</p>
              </div>
            </div>
          </Container>
        </section>
        <section className="section feature">
          <Container>
            <div className="feature-inner">
              <div className="feature-text">
                <h2 className="feature-intro">Smart calculations.</h2>
                <h3 className="feature-title">We do the math for you.  You just start scanning…everywhere.</h3>
                <hr className="feature-separator" />
                <p>Take the mystery out of selling on Amazon and eBay with our profit calculator. 
                  We account for all the blind spots from shipping costs to FBA fees.</p>
              </div>
              <div className="feature-image">
                <img src={feature2} alt="" className="img-fluid" />
              </div>
            </div>
          </Container>
        </section>
        <section className="section feature">
          <Container>
            <div className="feature-inner">
              <div className="feature-image">
                <img src={feature3} alt="" className="img-fluid" />
              </div>
              <div className="feature-text">
                <h2 className="feature-intro">Choose your speed</h2>
                <h3 className="feature-title">Built for speed and accuracy with multiple scanning options.</h3>
                <hr className="feature-separator" />
                <p>
                Leverage both LIVE and DATABASE scans with each product all for the same price. 
                We don't charge extra like the other guys to leverage the millions of products in our database.</p>
              </div>
            </div>
          </Container>
        </section>
      </div>
      
    )
  }
}

export default Features;