import React, { Component } from 'react';
import { Col, Container, Row } from "reactstrap";
import { iconList, iconMoney, iconSearch } from "../../../assets/icons";
import './style.css';

class Blurb extends Component {
  render() {
    return (
      <section className="section blurb">
        <Container>
          <Row>
            <Col sm={4}>
              <div className="blurb-item">
                <img src={iconSearch} alt="Search" className="blurb-icon" />
                <h3 className="blurb-title">Scan books, or anything!</h3>
                <p className="blurb-desc">Scan any product with a barcode and we'll tell you if it's worth flipping on Amazon or eBay</p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="blurb-item">
                <img src={iconList} alt="List" className="blurb-icon" />
                <h3 className="blurb-title">Empowered data for profitable decisions</h3>
                <p className="blurb-desc">Profit Hunt™ gives you all the data you need to make the right decision in a split second on Amazon or eBay</p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="blurb-item">
                <img src={iconMoney} alt="Money" className="blurb-icon" />
                <h3 className="blurb-title">Turn scans into profit!</h3>
                <p className="blurb-desc">Move your scans along the profit train and sell your items on Amazon, eBay or wholesale</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default Blurb;