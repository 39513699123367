import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import './style.css';
import { featureGrid1, featureGrid2, featureGrid3, featureGrid4, featureGrid5, featureGrid6, featureGrid7, featureGrid8, featureGrid9 } from "./images";

const data = [
  {
    img_url: featureGrid1, title: 'Never leave the camera in speed mode.',
    description: 'Scan products quickly in speed mode and never leave the camera. Perfect for retail stores, estate sales, or property clean outs.'
  },
  {
    img_url: featureGrid2, title: 'Let your lips do the typing…',
    description: 'Leverage speech-to-text and speak your ISBNs or titles of books and products into the Profit Hunt™ to see the potential profit.'
  },
  {
    img_url: featureGrid3, title: 'See your competition, clearly.',
    description: 'Scan any product and see other competing product listings, even Amazon.'
  },
  {
    img_url: featureGrid4, title: 'Step into your dashboard…',
    description: 'Get crucial information with every product scan like Buy Box pricing, Amazon’s pricing and each product’s pScore™'
  },
  {
    img_url: featureGrid5, title: 'Buy costs fluctuating on you?',
    description: 'No worries. Easily adjust buy costs on the fly in-app so you always know what your true profit is.'
  },
  {
    img_url: featureGrid6, title: 'We do the math for you…',
    description: 'We’ve taken account of all the fees you need to consider with each product scan so you get the true profit of each item.'
  },
  {
    img_url: featureGrid7, title: 'No signal?  No problem!',
    description: 'We’ve got millions of products in our database that you can leverage along with our refreshed data so you are never in the dark on profit, even in the basements.'
  },
  {
    img_url: featureGrid8, title: 'Never scan twice again…',
    description: 'With Profit Hunt™ you can scan once and add items to a waiting batch in AccelerList™ by setting custom MSKUS right in the mobile app.'
  },
  {
    img_url: featureGrid9, title: 'Grade it on the fly…',
    description: 'We allow sellers to set default condition grades for products or grade them individually on the fly saving you time later on in listing.'
  }
]

class FeaturesGrid extends Component {
  render() {
    return (
      <section className="section features-grid">
        <Container>
          <Row className="gx-5">
            {data.map((item, idx) => (
              <Col lg={4} sm={6}>
                <div className="features-grid-item">
                  <img src={item.img_url} alt="" className="img-fluid" />
                  <div className="features-grid-item-text">
                    <h3 className="features-grid-item-title">{item.title}</h3>
                    <p className="features-grid-item-description">
                      {item.description}
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    )
  }
}

export default FeaturesGrid;