const VideoPlayer = ({ id, title, videoClassname }) => (
  <div className="testimonial-video">
    <video
      className={`video-js ${videoClassname}`}
      controls
      preload="auto"
      width="100%"
      poster={`https://image.mux.com/${id}/thumbnail.jpg?width=350`}
      data-setup='{}'
    >
      <source src={id} type="video/mux" />
    </video>
    <div className="testimonial-video-overlay">
      <h4 className="testimonial-video-title">{title}</h4>
    </div>
  </div>
)

export default VideoPlayer